<template>
  <div>
    <h1>{{ $t("pageNotFound.title") }}</h1>
    <p>{{ $t("pageNotFound.content") }}</p>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>
